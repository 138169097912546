* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
}

ul {
    list-style-type: none;
    margin-bottom: 0;
}

caption {
    caption-side: top;
}

img {
    max-width: 100%;
    height: auto;
}
