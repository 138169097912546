@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import "~blueimp-gallery/css/blueimp-gallery.min.css";
@import "~glider-js/glider.min.css";

@import "vars";
@import "mixins";
@import "normalize";
@import "reset";
@import "pagination";
@import "post";
@import "hamburger";
@import "elements";
@import "articles";
@import "layout/page";
@import "layout/header";
@import "layout/navbar";
@import "layout/footer";
@import "layout/grid-list";
@import "pages/contact-form";
@import "pages/home";
@import "pages/gabinet";
@import "pages/zespol";
@import "pages/person";
@import "pages/contact";
@import "pages/co-leczymy";
@import "pages/specjalizacje";
