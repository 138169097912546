.home {
    position: relative;
    padding: 125px 0 0 0;
    overflow: hidden;

    .bg {
        position: absolute;
        background: url("../../images/home_bg.jpg") top center no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        max-height: 820px;
        top: 0;
        z-index: -9999;
    }

    .head {
        font-size: 2.5rem;
        font-weight: 400;
        text-align: center;
        width: 100%;
        padding-bottom: 30px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .quote {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: rgba(255, 255, 255, 0.38);
        color: #373737;
        font-family: 'Merriweather', serif;
        border: 3px solid white;
        max-width: 550px;
        padding: 40px 30px;

        &__quote {
            font-size: 1.8rem;
            font-weight: 500;
        }

        &__author {
            font-size: 1.8rem;
            font-weight: 500;
            text-align: right;
            width: 100%;
        }

        &__button-container {
            position: absolute;
            bottom: -20px;
            left: -5px;
            display: flex;
            justify-content: center;
            width: calc(100% + 10px);
            overflow: hidden;
        }
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-self: center;
        width: 100%;
        padding: 350px 12px 0 12px;

        @include mq(md) {
            flex-direction: row;
            padding: 350px 0 0 0;
        }

        &__box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            text-align: center;
            background: white;
            border: 5px solid #7dae0a;
            box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.14);
            height: 200px;
            width: 100%;
            margin: 0 10px 30px 10px;

            @include mq(md) {
                border: none;
                border-bottom: 5px solid #7dae0a;
            }
        }

        &__image {
            padding-bottom: 20px;
        }

        &__title {
            font-weight: 600;
            padding-bottom: 3px;
        }
    }

    .mocne-strony {
        width: 100%;
        padding-top: 30px;

        &__grid {
            display: grid;
            grid-template: auto / 100%;
            text-align: center;
            padding: 0 20px;

            @include mq(md) {
                grid-template: auto / repeat(3, calc(100% / 3));
                padding: 0;
            }
        }

        &__element {
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-content: center;
            height: 100%;
            padding: 30px 30px;
            border-bottom: 1px solid #dddddd;

            @include mq(md) {
                border-bottom: none;

                &:not(:nth-child(3n)) {
                    border-right: 1px solid #dddddd;
                }

                &:nth-child(-n + 3) {
                    border-bottom: 1px solid #dddddd;
                }
            }

            @include mq(lg) {
                padding: 30px 60px;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        &__title {
            color: #7dae0a;
            font-weight: 600;
        }

        &__arrow {
            height: 6px;
            width: 10px;
            margin: 15px auto;
        }
    }

    .reviews {
        background: #e5e5e5;
        width: 100%;
        margin-top: 120px;
        padding: 30px 0 30px 0;

        &__wrapper {
            position: relative;
        }

        &__nav {
            position: absolute;
            top: 115px;
            cursor: pointer;

            @include mq(sm) {
                top: 7px;
            }

            &--prev {
                left: 31px;
            }

            &--next {
                right: 31px;
            }
        }

        &__arrow {
            &--rotate {
                transform: rotate(180deg);
            }
        }

        &__box {
            display: flex;
            align-items: flex-start;
            padding-bottom: 40px;
        }

        &__element {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: white;
            max-width: 350px;
            margin: 40px 25px 0 25px;
            padding: 15px 15px 15px 15px;
        }

        &__name {
            font-size: 1.3em;
            font-weight: 700;
            padding-bottom: 10px;
        }

        &__row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 20px;

            @include mq(sm) {
                flex-direction: row;
            }
        }

        &__stars {
            @include mq(sm) {
                margin-right: 15px;
            }
        }

        &__when {
            color: #a89f9f;
        }

        &__review {
            text-align: justify;
            text-align-last: center;
            padding-bottom: 10px;
        }

        &__google-box {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
        }

        &__google {

        }

        &__dots {
            .glider-dot {
                background: white;
                height: 18px;
                width: 18px;

                &.active {
                    background: #7dae0a;
                }
            }
        }
    }
}
