.header {
    width: 100%;
    padding: 15px 0;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include mq(md) {
            flex-direction: row;
        }
    }
}

.logo {
    @include mq(md) {
        margin-left: 40px;
    }

    @include mq(lg) {
        margin-left: 0;
    }

    &__link {
        display: block;
    }

    &__image {

    }
}

.appointment {
    padding-top: 10px;

    @include mq(md) {
        padding-top: 0;
    }

    &__button {
        display: block;
        font-size: 1.2rem;
        font-weight: 600;
        padding: 10px 20px;

        @include mq(md) {
            font-size: 1.4rem;
            padding: 20px 30px;
        }
    }
}
