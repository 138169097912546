#contact-form {
    margin-top: 30px;

    h2 {
        text-align: center;
    }

    form {
        .row {
            display: flex;
            flex-direction: column;
            margin: 0;

            @include mq("lg") {
                flex-direction: row;
                margin: 0 -16px 0 0;

                > div {
                    width: calc(100% / 3);

                    input {
                        width: 95%;
                    }
                }
            }
        }
    }
}
