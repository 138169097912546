.zespol {
    margin-bottom: 60px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .head {
        display: none;
    }
}

.team {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
    overflow: hidden;

    &__box {
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__elements {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding-top: 90px;

        @include mq(md) {
            padding-top: 175px;
        }
    }

    &__element {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border: 5px solid #7dae0a;
        border-radius: 30px;
        width: 240px;
        padding: 100px 20px 60px 20px;
        transform: scale(0.5);

        @include mq(xs) {
            width: 300px;
        }

        @include mq(md) {
            padding: 150px 20px 60px 20px;
            width: 450px;
        }

        &.active {
            transform: scale(1);
        }
    }

    &__arrow {
        cursor: pointer;
        position: absolute;
        top: 400px;
        left: 50%;
        z-index: 9999;

        &--left {
            transform: translateX(-146px);

            @include mq(xs) {
                transform: translateX(-196px);
            }

            @include mq(md) {
                transform: translateX(-306px);
            }
        }
    
        &--right {
            transform: rotate(180deg) translateX(-125px);

            @include mq(xs) {
                transform: rotate(180deg) translateX(-175px);
            }

            @include mq(md) {
                transform: rotate(180deg) translateX(-284px);
            }
        }
    }

    &__image {
        position: absolute;
        top: -100px;
        border: 10px solid white;
        border-radius: 50%;
        max-width: 200px;

        @include mq(md) {
            border: 20px solid white;
            top: -155px;
            max-width: 290px;
        }
    }

    &__name {
        font-size: 1.7rem;
        font-weight: 700;
        padding-bottom: 10px;
    }

    &__specialization {
        font-size: 0.8rem;
        font-weight: 600;
        padding-bottom: 25px;
    }

    &__text {
        text-align: justify;
    }

    &__button-box {
        position: absolute;
        bottom: -20px;
        border-left: 10px solid white;
        border-right: 10px solid white;

        @include mq(sm) {
            border-left: 30px solid white;
            border-right: 30px solid white;
        }
    }
}
