$breakpoints: (
  "xs": 430px,
  "sm": 490px,
  "md": 767px,
  "lg": 1000px
);

@mixin mq($breakpoint) {
  $breakpoint: map-get($breakpoints, $breakpoint);
  @if ($breakpoint) {
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  }
}
