.gabinet {
    margin-bottom: 60px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include mq(lg) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        line-height: 1.4;

        &--left {
            max-width: 500px;
            margin-top: 20px;
            margin-bottom: 30px;

            @include mq(lg) {
                margin-bottom: 0;
            }
        }

        &--right {
            position: relative;
            margin-left: 25px;

            @include mq(lg) {
                margin-left: 30px;
            }

            &:after {
                position: absolute;
                content: "";
                box-sizing: border-box;
                border: 5px solid #7dae0a;
                border-radius: 50px;
                height: 100%;
                width: 100%;
                bottom: -25px;
                left: -25px;
            }
        }
    }

    &__subtitle {
        font-size: 1.8rem;
        font-weight: 600;

        @include mq(lg) {
            font-size: 2.3rem;
        }

        span {
            color: #7dae0a;
        }
    }

    &__bar {
        background: #7dae0a;
        height: 4px;
        width: 100%;
        max-width: 150px;
        margin: 30px 0;
    }

    &__text {
        text-align: justify;
    }

    &__image {
        border-radius: 50px;
    }
}
