.article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #494846;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 40px;

    @include mq(md) {
        flex-direction: row;
    }

    @include mq(lg) {
        font-size: 1rem;
    }

    &__column {
        position: relative;

        &--left {
            padding: 0 20px 30px 20px;

            @include mq(md) {
                width: 50%;
                padding-bottom: 0;
            }

            @include mq(lg) {
                padding: 0 50px 0 40px;
            }

            &:before {
                position: absolute;
                content: '';
                background: $primary;
                width: 5px;
                height: 100px;
                top: 36px;
                left: 0;
            }
        }

        &--right {
            display: flex;
            justify-content: center;
            width: 100%;

            @include mq(md) {
                justify-content: flex-end;
                width: 50%;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__date {

    }

    &__title-box {
        margin-bottom: 20px;
    }

    &__title {
        color: #494846;
        font-size: 1.6em;
        text-decoration: none;
        text-align: justify;

        @include mq(lg) {
            font-size: 2em;
        }
    }

    &__text {
        text-align: justify;
        margin-bottom: 20px;
    }

    &__link {
        color: $primary;
        text-decoration: none;
    }
}

.post {
    margin-bottom: 60px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .page-title {
        margin-bottom: 40px;
    }

    &__cms-content {
        text-align: justify;

        @include mq(lg) {
            padding-left: 40px;
        }
    }
}
