nav.pagination {
    width: 100%;
}
ul.pagination {
    display: flex;
    flex-wrap: nowrap;
    margin: auto;

    li {
        list-style: none;
        font-size: 14px;
        margin-right: 10px;
        min-width: 27px;
        text-align: center;
        padding: 0 3px;
        display: flex;
        justify-content: center;
        align-content: center;
        line-height: 2;

        &.active {
            background: #1f7831;
            color: white;
        }

        &:first-child, &:last-child {
            font-size: 27px;
            font-weight: 700;
            line-height: 1;
            margin-top: -2px;
        }

        > a {
            color: #484644;
            text-decoration: none;
        }
    }
}
