.specjalizacje {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-bottom: 50px;

    .row {
        display: flex;
        flex-direction: column;
        gap: 30px;
        border-left: 4px solid #7dae0a;
        padding-left: 30px;

        h3 {
            font-size: 2rem;
            cursor: pointer;

            &.selected {
                color: #7dae0a;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
}