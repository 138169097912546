.appointment-box {
    background: #aaaaaa;
    padding: 50px 0;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 150px;
    }

    &__text {
        color: white;
        font-size: 1.3rem;
        text-align: center;
        padding-bottom: 25px;

        @include mq(md) {
            font-size: 1.7rem;
        }
    }
}

.infos {
    background: #6a7079;
    padding: 50px 0;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @include mq(lg) {
            flex-direction: row;
        }
    }

    .contact {
        flex-grow: 1;
        display: flex;flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width: 1200px;

        @include mq(lg) {
            flex-direction: row;
        }

        &__column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            padding-bottom: 25px;

            @include mq(lg) {
                flex-direction: row;
                text-align: left;
                padding-bottom: 0;
            }
        }

        &__divider {
            display: none;

            @include mq(lg) {
                display: block;
                height: 100%;
                min-height: 36px;
                width: 1px;
                background: #c2c2c2;
            }
        }

        &__image {
            padding-bottom: 3px;

            @include mq(lg) {
                padding-bottom: 0;
                padding-right: 10px;
            }
        }

        &__text-box {
            display: flex;
            flex-direction: column;
            color: white;
        }

        &__title {
            font-weight: 600;
        }
    }
}

.copyright {
    background: #4c525a;
    padding: 25px 0;

    &__wrapper {
        width: 100%;
    }

    &__text {
        color: #909ba2;
        font-size: 0.8rem;
        text-align: center;

        @include mq(lg) {
            text-align: left;
        }
    }

    &__link {
        color: #909ba2;
        text-decoration: none;
    }
}
