.page-title {
    border-bottom: 4px solid #7dae0a;
    padding-bottom: 15px;
}

.button {
    display: block;
    color: white;
    text-decoration: none;
    border: 1px solid white;
    border-radius: 22.5px;
    padding: 10px 20px;

    &:hover {
        text-decoration: none;
    }

    &--green {
        background: #7dae0a;
        border-color: #7dae0a;
    }
}
