.navbar {
    padding-top: 50px;
    position: absolute;
    background: white;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 20;
    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

    @include mq(lg) {
        font-size: 1.1rem;
        padding-top: 0;
        position: static;
        background: none;
        min-height: initial;
        height: auto;
        width: auto;
        transform: translate(0, 0);
    }

    &--active {
        transform: translate(0, 0);
    }

    &__list {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @include mq(lg) {
            flex-direction: row;
            align-items: center;
        }

        &--second {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include mq(lg) {
                display: none;
                position: absolute;
                background: #7dae0a;
                top: 48px;
                left: 0;
                padding: 0 15px;
                width: auto;
                min-width: 170px;
            }
        }
    }

    &__item {
        position: relative;
        width: 100%;

        @include mq(lg) {
            width: auto;
        }

        &:hover {
            .navbar__list--second {
                display: flex;
            }

            @include mq(lg) {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 5px;
                    left: calc(50% - 5px);
                    background-image: url('../../images/navbar_arrow.png');
                    height: 6px;
                    width: 10px;
                }
            }
        }

        &--selected {
            background: #7dae0a;

            > .navbar__link {
                color: white;
            }

            @include mq(lg) {
                background: none;

                > .navbar__link {
                    color: #5a5a5a;
                }
            }
        }

        &--second {
            padding-left: 30px;

            @include mq(lg) {
                padding-left: 0;

                &:not(:first-child) {
                    border-top: 1px solid white;
                }

                &.navbar__item--selected a {
                    color: white;
                }
            }
        }
    }

    &__link {
        display: block;
        color: #5a5a5a;
        font-weight: 500;
        text-decoration: none;
        width: 100%;
        padding: 15px 15px;

        @include mq(lg) {
            padding: 15px 15px;
        }

        &:hover {
            color: #7dae0a;
        }

        &--second {
            @include mq(lg) {
                width: 100%;
                color: white;

                &:hover {
                    color: white;
                    font-weight: 600;
                }
            }
        }
    }
}
