.grid-list {
    margin-bottom: 60px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;

        @include mq(lg) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__images {
        display: grid;
        grid-template: auto / repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 10px;
        width: 100%;

        @include mq(sm) {
            grid-template: auto / repeat(auto-fit, minmax(140px, 1fr));
        }

        @include mq(lg) {
            grid-template: auto / repeat(3, 1fr);
            width:auto;
        }
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 10px solid #f6f7f5;
        height: 100%;
        aspect-ratio: 1;

        @include mq(sm) {
            border-width: 30px;
        }

        @include mq(lg) {
            height: 150px;
            width: 150px;
            margin: auto;
        }

        &--selected,
        &:hover {
            border-color: $primary;
            cursor: pointer;

            .specjalizacje__image {
                filter: invert(84%) sepia(37%) saturate(7499%) hue-rotate(37deg) brightness(91%) contrast(92%);
            }
        }
    }

    &__image {
        padding: 5px;
    }

    &__text-box {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 30px;

        @include mq(lg) {
            margin-top: 0;
            margin-left: 20px;
        }
    }

    &__title {
        font-size: 2.4rem;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &__description {
        margin-bottom: 10px;

        > * {
            margin-bottom: 10px;
        }
    }

    &__more {
        color: $primary;
        font-weight: 500;
        text-decoration: none;
    }
}
