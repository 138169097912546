body {
    color: #5a5a5a;
    font-family: 'Barlow', sans-serif;
}

.wrapper {
    max-width: 1200px;
    margin: auto;
    padding: 0 7px;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 598px);
    width: 100%;

    > div {
        width: 100%;
    }
}


