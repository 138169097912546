.co-leczymy {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        @include mq("md") {
            flex-direction: row;
            justify-content: space-between;
            gap: 0;
        }

        &:nth-child(even) {
            flex-direction: column-reverse;

            @include mq("md") {
                flex-direction: row;
            }
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 450px;

            &:nth-child(even) {
                h3 {
                    @include mq("md") {
                        text-align: right;
                    }
                }
            }

            &:nth-child(odd) {
                h3 {
                    color: #7dae0a;
                }
            }

            h3 {
                font-size: 1.2rem;
                margin-bottom: 20px;

                @include mq("lg") {
                    font-size: 2rem;
                }
            }

            p {
                text-align: justify;
            }
        }

        .image-container {
            display: flex;
            justify-content: flex-end;
            height: 100%;
        }
    }
}