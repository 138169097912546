.person {
    margin-bottom: 60px;
    line-height: 1.4;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__row {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        @include mq(lg) {
            flex-direction: row;
        }
    }

    &__column {
        &--left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            align-self: center;
            margin-top: 30px;

            @include mq(lg) {
                align-self: flex-start;
                margin-top: 0;
            }
        }

        &--right {
            max-width: 770px;
        }
    }

    &__image-caption {
        font-weight: 600;
        margin-top: 10px;
    }

    &__name {
        font-weight: 600;
        margin-bottom: 30px;
    }

    &__paragraph {
        margin-bottom: 15px;
    }

    .qualifications {
        margin-top: 30px;
        font-size: 1.1rem;
        font-weight: 600;

        @include mq(lg) {
            font-size: 1.3rem;
        }

        &__title {
            color: $primary;
            font-size: 1.2em;
        }

        &__box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            border-left: 4px solid $primary;
            padding-top: 10px;
            padding-left: 15px;
        }

        &__year {
            color: $primary;
            margin-right: 5px;
        }

        p {
            margin-bottom: 15px;
        }
    }
}
