.contact-page {
    margin-bottom: 60px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;

        @include mq(lg) {
            flex-direction: row;
        }
    }

    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        @include mq(lg) {
            margin-bottom: 0;
        }
    }

    &__line {
        @include mq(lg) {
            background: silver;
            height: 80px;
            width: 1px;
        }
    }

    &__image {
        padding-right: 15px;
    }

    &__text {
        font-weight: 500;
        text-align: center;

        @include mq(sm) {
            font-size: 1.4rem;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .map {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        font-size: 1.2rem;
        margin-bottom: 30px;
        width: 100%;

        @include mq(lg) {
            flex-direction: row;
        }

        &__column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            background: #f6f6f6;
            padding: 20px 10px;

            @include mq(lg) {
                min-height: 250px;
                width: 30%;
            }
        }

        &__town {
            color: $primary;
            font-size: 1.6em;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        &__address {
            text-align: center;
        }

        &__map-box {
            height: 50vh;

            @include mq(lg) {
                height: auto;
                width: 70%;
            }
        }

        &__map {
            height: 100%;
            width: 100%;
        }
    }
}
